<template>
  <!--特征分析 -->
  <div
    id="Characteristic"
    v-loading="downloadLoading"
    :element-loading-text="
      downloadRole
        ? this.$t(`message.loading.downloading`)
        : loading
        ? this.$t(`message.loading.suanfa`)
        : downloadRole
        ? this.$t(`message.loading.downloadcompletes`)
        : this.$t(`message.loading.Successsuanfa`)
    "
    style="width: 100%">
    <div class="loading" v-show="loading">
      <div class="loading-content">
        <img src="https://newstore.vynior.com/close1%402x.png" alt="" class="loading-close" @click="loadingClose" />
        <el-progress :percentage="loadingProgress" :show-text="false" color="#fff" :stroke-width="4" class="loading-progress"></el-progress>
        <div class="loading-text">
          {{ loadingProgress >= 100 && analyzeStatus ? this.$t(`message.loading.Successsuanfa`) : this.$t(`message.loading.suanfas`) }}
        </div>
        <!-- <div class="loading-text2">
          {{ $t(`message.loading.tooltip`) }}<span>{{ $t(`message.loading.minutes`) }}</span>
        </div> -->
      </div>
    </div>
    <div :class="$store.state.lang == 'en' ? 'english' : ''">
      <Header :info="info"></Header>
      <!-- 选择项目\选择产品 -->
      <div class="header_bar">
        <!-- 第一行 -->
        <div class="bar_One">
          <div class="Page_title">
            {{ $t(`message.Category.page3.Title`) }}
            <div class="line"></div>
          </div>

          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item :label="$t(`message.Category.common.Category`)" class="bar_One_text_0">
              <el-select v-model="formInline0.product" :placeholder="$t(`message.Category.page4.SelectOne`)" @change="changeName">
                <el-option v-for="(item, index) in category_list" :key="index" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <!-- 感官对比。            。。。。。 -->
          <div class="block">
            <span class="demonstration">{{ $t(`message.Category.common.Products`) }}</span>
            <el-cascader
              v-model="contrast"
              :value="values"
              :placeholder="$t(`message.Category.common.Select`)"
              :options="options"
              :show-all-levels="false"
              :props="{ multiple: true, emitPath: true }"
              filterable
              ref="cascaderAddr"
              clearable
              @change="Cvalue"
              :label="label"></el-cascader>
          </div>
          <!-- 中文 -->
          <div class="button" v-show="$store.state.lang == 'cn'">
            <div class="rest" @click="clear_num">
              <img src="https://newstore.vynior.com/rest_choose.png" alt="" />
            </div>

            <div class="compare">
              <img id="downloadImg" @click="downloadShow = !downloadShow" src="https://newstore.vynior.com/download.png" alt="" />
              <div class="download-select" v-show="downloadShow">
                <div class="select-item" @click="downloadIMG()">单张下载</div>
                <div class="select-item" @click="downloadIMGALL()">全部下载</div>
              </div>
            </div>

            <div class="port" @click="req_ana">
              <img src="https://newstore.vynior.com/ksfx.png" alt="" />
            </div>
          </div>
          <!-- 英文 -->
          <div class="button" v-show="$store.state.lang == 'en'">
            <div class="rest">
              <img src="https://newstore.vynior.com/rrrrssst.png" alt="" @click="clear_num" />
            </div>

            <div class="compare">
              <img id="downloadImg" @click="downloadShow = !downloadShow" src="https://newstore.vynior.com/xzxz.png" alt="" />
              <div class="download-select" v-show="downloadShow">
                <div class="select-item" @click="downloadIMG()">{{ $t(`message.Category.common.DownloadGraph`) }}</div>
                <div class="select-item" @click="downloadIMGALL()">{{ $t(`message.Category.common.DownloadAll`) }}</div>
              </div>
            </div>

            <div class="port">
              <img src="https://newstore.vynior.com/fenxifx.png" alt="" @click="req_ana" />
            </div>
          </div>
        </div>
      </div>

      <!-- 下面的内容box -->
      <div class="Content_box">
        <!-- 左侧 选择器 -->
        <div class="left">
          <div class="Product_title" :class="change_ === false ? 'change_show' : ''">
            <div class="Product_title-t">
              <div class="line1">
                <div class="pdc_tip">{{ $t(`message.Category.page3.Factor`) }}</div>
                <!-- <div class="reset" @click="reset_" v-show="reset_show"> -->
                <div class="reset" @click="reset_" v-show="$store.state.lang == 'cn'">
                  <img src="https://newstore.vynior.com/56x.png" alt="" />
                </div>
                <div class="reset" @click="reset_" v-show="$store.state.lang == 'en'">
                  <img src=" https://newstore.vynior.com/Group%20569%402x.png" alt="" />
                </div>
              </div>
              <div class="line2">
                <el-form ref="form" :model="pdc_c" label-width="80px">
                  <el-select v-model="pdc_cs" :placeholder="$t(`message.Category.page3.Select`)">
                    <!--:disabled="product_not_null"  -->
                    <el-option v-for="(item, index) in ff" :key="index" :label="item" :value="index"></el-option>
                    <!-- <el-option label="区域二" value="beijing"></el-option> -->
                  </el-select>
                </el-form>
              </div>

              <div class="line4" v-show="change_show">
                <ul>
                  <li>
                    <el-radio v-model="radio" label="1">{{ $t(`message.Category.page3.PCA`) }}</el-radio>
                  </li>
                  <li class="li2">
                    <el-radio v-model="radio" label="2">{{ $t(`message.Category.page3.Rotated`) }}</el-radio>
                    <!-- <el-radio v-model="radio" label="2">{{ $t(`message.Category.page3.Rotated`) }}</el-radio> -->
                  </li>
                </ul>
              </div>
              <div
                v-show="change_show == 0"
                class="collapse"
                :class="
                  seletshow == 1
                    ? radio == 1
                      ? 'collapse collapse-click collapse-show'
                      : 'collapse collapse-show'
                    : radio == 1
                    ? seletshow != 1
                      ? 'collapse collapse-click'
                      : 'collapse collapse-click collapse-show'
                    : 'collapse '
                ">
                <div class="collapse-button">
                  <el-radio v-model="radio" :label="1">{{ $t(`message.Category.page3.PCA`) }}</el-radio>
                  <i
                    :class="seletshow == 1 ? 'el-collapse-item__arrow el-icon-arrow-right is-active' : 'el-icon-arrow-right el-collapse-item__arrow'"
                    @click="seletshow == 1 ? (seletshow = 0) : (seletshow = 1)"></i>
                </div>
                <div class="text" v-show="seletshow == 1">
                  <div
                    class="select-item"
                    :style="{
                      background: radios == 1 ? ' linear-gradient(99deg, #E1EAF2 0%, rgba(225,234,242,0) 100%)' : '#fff',
                    }">
                    <el-radio v-model="radios" label="1">{{ $t(`message.Category.page3.Clustering`) }}</el-radio>
                    <div class="ChooseClass">
                      <span @click.stop="ChangeIsShow($event)">
                        {{ showClassNames }}
                      </span>
                      <div class="imgHeight">
                        <img src="https://newstore.vynior.com/xialas.png" alt="" @click.stop="ChangeIsShow($event)" />
                      </div>
                    </div>
                    <div class="chooiceList" v-show="isShow">
                      <div class="shadow2">
                        <div class="shadow3" v-show="$store.state.lang == 'cn'">
                          <div class="list1" :class="showClassNames == '默认' ? 'clickBack' : ''" @click="showClassNames = '默认'">
                            {{ $t(`message.Category.page3.None`) }}
                          </div>
                          <div class="list2" :class="showClassNames == '4' ? 'clickBack' : ''" @click="showClassNames = '4'">
                            {{ $t(`message.Category.page3.Clusters4`) }}
                          </div>
                          <div class="list2" :class="showClassNames == '6' ? 'clickBack' : ''" @click="showClassNames = '6'">
                            {{ $t(`message.Category.page3.Clusters6`) }}
                          </div>
                          <div class="list2" :class="showClassNames == '8' ? 'clickBack' : ''" @click="showClassNames = '8'">
                            {{ $t(`message.Category.page3.Clusters8`) }}
                          </div>
                          <div class="list3" :class="showClassNames == '12' ? 'clickBack' : ''" @click="showClassNames = '12'">
                            {{ $t(`message.Category.page3.Clusters12`) }}
                          </div>
                        </div>
                        <div class="shadow3" v-show="$store.state.lang == 'en'">
                          <div class="list1" :class="showClassNames == 'None' ? 'clickBack' : ''" @click="showClassNames = 'None'">
                            {{ $t(`message.Category.page3.None`) }}
                          </div>
                          <div class="list2" :class="showClassNames == '4' ? 'clickBack' : ''" @click="showClassNames = '4'">
                            {{ $t(`message.Category.page3.Clusters4`) }}
                          </div>
                          <div class="list2" :class="showClassNames == '6' ? 'clickBack' : ''" @click="showClassNames = '6'">
                            {{ $t(`message.Category.page3.Clusters6`) }}
                          </div>
                          <div class="list2" :class="showClassNames == '8' ? 'clickBack' : ''" @click="showClassNames = '8'">
                            {{ $t(`message.Category.page3.Clusters8`) }}
                          </div>
                          <div class="list3" :class="showClassNames == '12' ? 'clickBack' : ''" @click="showClassNames = '12'">
                            {{ $t(`message.Category.page3.Clusters12`) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="select-item"
                    :style="{
                      background: radios == 3 ? ' linear-gradient(99deg, #E1EAF2 0%, rgba(225,234,242,0) 100%)' : '#fff',
                    }">
                    <el-radio v-model="radios" label="3">{{ $t(`message.Category.page3.SPFValues`) }}</el-radio>
                  </div>
                  <div
                    class="select-item"
                    :style="{
                      background: radios == 4 ? ' linear-gradient(99deg, #E1EAF2 0%, rgba(225,234,242,0) 100%)' : '#fff',
                    }">
                    <el-radio v-model="radios" label="4">{{ $t(`message.Category.page3.FormulationSystem`) }}</el-radio>
                  </div>
                </div>
              </div>
              <div
                v-show="change_show == 0"
                class="collapse collapse2"
                :class="
                  seletshow == 2
                    ? radio == 2
                      ? 'collapse collapse-click collapse-show'
                      : 'collapse collapse-show'
                    : radio == 2
                    ? seletshow != 2
                      ? 'collapse collapse-click'
                      : 'collapse collapse-click collapse-show'
                    : 'collapse '
                ">
                <div class="collapse-button">
                  <el-radio v-model="radio" :label="2">{{ $t(`message.Category.page3.Rotated`) }}</el-radio>
                  <i
                    :class="seletshow == 2 ? 'el-collapse-item__arrow el-icon-arrow-right is-active' : 'el-icon-arrow-right el-collapse-item__arrow'"
                    @click="seletshow == 2 ? (seletshow = 0) : (seletshow = 2)"></i>
                </div>
                <div class="text" v-show="seletshow == 2">
                  <div
                    class="select-item"
                    :style="{
                      background: radioss == 5 ? ' linear-gradient(99deg, #E1EAF2 0%, rgba(225,234,242,0) 100%)' : '#fff',
                    }">
                    <el-radio v-model="radioss" label="5">{{ $t(`message.Category.page3.Clustering`) }} </el-radio>
                    <div class="ChooseClass">
                      <span @click.stop="ChangeIsShow($event)">
                        {{ showClassName }}
                      </span>
                      <div class="imgHeight">
                        <img src="https://newstore.vynior.com/xialas.png" alt="" @click.stop="ChangeIsShow($event)" />
                      </div>
                    </div>
                    <div class="chooiceList" v-show="isShow">
                      <div class="shadow2">
                        <div class="shadow3" v-show="$store.state.lang == 'cn'">
                          <div class="list1" :class="showClassName == '默认' ? 'clickBack' : ''" @click="showClassName = '默认'">
                            {{ $t(`message.Category.page3.None`) }}
                          </div>
                          <div class="list2" :class="showClassName == '4' ? 'clickBack' : ''" @click="showClassName = '4'">
                            {{ $t(`message.Category.page3.Clusters4`) }}
                          </div>
                          <div class="list2" :class="showClassName == '6' ? 'clickBack' : ''" @click="showClassName = '6'">
                            {{ $t(`message.Category.page3.Clusters6`) }}
                          </div>
                          <div class="list2" :class="showClassName == '8' ? 'clickBack' : ''" @click="showClassName = '8'">
                            {{ $t(`message.Category.page3.Clusters8`) }}
                          </div>
                          <div class="list3" :class="showClassName == '12' ? 'clickBack' : ''" @click="showClassName = '12'">
                            {{ $t(`message.Category.page3.Clusters12`) }}
                          </div>
                        </div>
                        <div class="shadow3" v-show="$store.state.lang == 'en'">
                          <div class="list1" :class="showClassName == 'None' ? 'clickBack' : ''" @click="showClassName = 'None'">
                            {{ $t(`message.Category.page3.None`) }}
                          </div>
                          <div class="list2" :class="showClassName == '4' ? 'clickBack' : ''" @click="showClassName = '4'">
                            {{ $t(`message.Category.page3.Clusters4`) }}
                          </div>
                          <div class="list2" :class="showClassName == '6' ? 'clickBack' : ''" @click="showClassName = '6'">
                            {{ $t(`message.Category.page3.Clusters6`) }}
                          </div>
                          <div class="list2" :class="showClassName == '8' ? 'clickBack' : ''" @click="showClassName = '8'">
                            {{ $t(`message.Category.page3.Clusters8`) }}
                          </div>
                          <div class="list3" :class="showClassName == '12' ? 'clickBack' : ''" @click="showClassName = '12'">
                            {{ $t(`message.Category.page3.Clusters12`) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="select-item"
                    :style="{
                      background: radioss == 7 ? ' linear-gradient(99deg, #E1EAF2 0%, rgba(225,234,242,0) 100%)' : '#fff',
                    }">
                    <el-radio v-model="radioss" label="7">{{ $t(`message.Category.page3.SPFValues`) }}</el-radio>
                  </div>
                  <div
                    class="select-item"
                    :style="{
                      background: radioss == 8 ? ' linear-gradient(99deg, #E1EAF2 0%, rgba(225,234,242,0) 100%)' : '#fff',
                    }">
                    <el-radio v-model="radioss" label="8">{{ $t(`message.Category.page3.FormulationSystem`) }}</el-radio>
                  </div>
                </div>
              </div>
            </div>
            <!-- 图标描述 显示的状态 -->
            <div class="tip" v-show="showChart == 1 && $store.state.lang == 'cn'">
              <img src="https://newstore.vynior.com/tbboxms.png" alt="" />
              <div class="text2" @click="showChart = 0">{{ $t(`message.Category.common.Hide`) }}</div>
            </div>
            <!-- 图标描述 显示的状态 english-->
            <div class="tip" v-show="showChart == 1 && $store.state.lang == 'en'">
              <img src="https://newstore.vynior.com/Group%2033729%402x.png" alt="" />
              <div class="text2" @click="showChart = 0">{{ $t(`message.Category.common.Hide`) }}</div>
            </div>
            <!-- 图标描述 隐藏的状态 -->
            <div class="tips" v-show="showChart == 0 && $store.state.lang == 'cn'">
              <img src="https://newstore.vynior.com/Group%2033821.png" alt="" />
              <div class="text2" @click="showChart = 1">{{ $t(`message.Category.common.Show`) }}</div>
            </div>
            <!-- 图标描述 隐藏的状态 english-->
            <div class="tips" v-show="showChart == 0 && $store.state.lang == 'en'">
              <img src="https://newstore.vynior.com/emptymiaos.png" alt="" />
              <div class="text2" @click="showChart = 1">{{ $t(`message.Category.common.Show`) }}</div>
            </div>
          </div>
        </div>
        <!-- 右侧 内容图 -->
        <div class="Right">
          <div class="box_button">
            <div class="SwitchBox" v-if="!change_">
              <div class="title">{{ $t(`message.Category.page3.Legend`) }}</div>
              <div class="switch">
                <el-switch v-model="Switch_Value" active-color="#1D5CA6"> </el-switch>
              </div>
            </div>
            <div class="SwitchBox_2" v-show="change_show == 0">
              <div class="title">{{ $store.state.lang == 'cn' ? '属性编号' : 'Code' }}</div>
              <div class="switch">
                <el-switch v-model="Name_Value" active-color="#1D5CA6"> </el-switch>
              </div>
            </div>
          </div>
          <div class="content_data" v-show="change_show == 0">
            <div class="empty_log" v-show="change_show == 1">
              <img src=" https://newstore.vynior.com/kongs-1.png" alt="" />
              <div class="text">
                <p>{{ $t(`message.Category.common.empty`) }}</p>
              </div>
            </div>
            <div class="img_data" v-show="change_show == 0" style="margin-top: -30px">
              <!--       基础前面的字段        -->
              <img :src="`${base}${show_img1[show_img1_index]}${show_img2[show_img2_index]}${show_img3[show_img3_index]}${show_img4}`" alt="" />
              <!-- {{ `${base}${show_img1[show_img1_index]}${show_img2[show_img2_index]}${show_img3[show_img3_index]}${show_img4}` }} -->
            </div>
            <div class="img_data" v-show="change_show == 3">
              <img src="https://newstore.vynior.com/none%202.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!-- 关闭分析 -->
      <div class="cancel_ana" v-show="cancel_req_show">
        <div class="content">
          <img src="https://newstore.vynior.com/chaoshi.png" alt="" @click="cancel_req" />
          <p>算法生成超时</p>
          <p>请重新预测</p>
        </div>
      </div>

      <!-- 确认离开页面操作 -->
      <div class="notarize_delete" v-show="Leave">
        <!-- 遮罩层 -->
        <!-- 提示框 -->
        <div class="notarize_message">
          <!-- 内容信息 -->
          <div class="content_text">
            <div class="line_1" v-show="$store.state.lang == 'cn'">
              您确定离开&nbsp; <span class="all_num"> 特征分析 </span>&nbsp; 页面吗?
              <img src="https://newstore.vynior.com/xxx.png" alt="" @click="Leave = false" />
            </div>
            <div class="line_1" v-show="$store.state.lang == 'en'">
              <div class="text">Are you sure you want to leave?</div>

              <img src="https://newstore.vynior.com/xxx.png" alt="" @click="Leave = false" />
            </div>
            <div class="line_2">
              <img src="https://newstore.vynior.com/gth.png" alt="" />
              {{ $t(`message.Category.page3.detail`) }}
            </div>
            <div class="line_3">
              <span class="cancle" @click="clearFocus">{{ $t(`message.Project.Cancel`) }}</span>
              <span class="sure" @click="goPathName">{{ $t(`message.Project.OK`) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { message } from '@/utils/resetMessage';
import { HttpClient } from '@/utils/http';
import Header from '@/components/Header';
import getFilename from '../../utils/getFilename';
export default {
  name: 'Characteristic',
  components: {
    Header,
  },
  data() {
    return {
      Times: 0,
      Name_Value: false,
      next: false,
      pathName: '',
      Once_sure: 0,
      Leave: false,
      timenum: 0,
      category: '',
      switchShow: true, //显示隐藏switch
      classNum: [1, 2, 3, 4, 11, 12, 13, 14], //判断是否选择了类
      currentNum: 0,

      showClassNames: '默认', //选择类
      Switch_Value: false, //图例显示
      showClassName: '默认', //选择类
      isShow: 0, //默认展开的下拉(分类选择)
      seletshow: 0,
      downloadShow: false,
      reset_show: 0, //重置 显示/隐藏
      downloadLoading: false,
      loadingtimer: null,
      analyzeStatus: true,
      loadingProgress: 0, // 算法进度
      info: 7,
      cancel_req_show: 0, //显示/隐藏请求超时
      time: null,
      result_data: '', //最终结果数组
      timer: 0,
      values: '',
      // values: "",
      product_not_null: true, //判定选择坐标是否禁用
      pdc_cs: '',
      img_data: '',
      change_show: 1,
      change_: true,
      loading: false, //加载算法
      figure_up: [],
      ff: ['F1&F2', 'F1&F3', 'F1&F4', 'F1&F5', 'F1&F6', 'F2&F3', 'F2&F4', 'F2&F5', 'F2&F6', 'F3&F4', 'F3&F5', 'F3&F6', 'F4&F5', 'F4&F6', 'F5&F6'],
      SelectValue: '',
      panel_list: [],
      panel_data: [],
      idlist: [],
      label: '111111',
      contrast: '', //已选的对比产品数据
      contrasts: '',
      disabled: true,
      forms: '',
      value: false, //switch 滑块
      radio: 0, //单选模块
      formInline0: { region: '', product: '' },
      formInline: { region: '', product: '' },
      formInlines: { region: '', product: '' },
      pdc_c: { region: '', product: '' },
      category_list: [], //选择品类
      program_name: [], //存储项目名称
      product_name: [],
      // formInline0: [],
      category_arrs: [], ///产品品类数组
      options: [],
      taskID: '',
      downloadRole: false,
      showChart: 1, //控制图表描述的显示和隐藏
      radios: 0,
      radioss: 0,
      activeNames: 0,
      index: 0,

      // !图片数据
      base: 'https://www.ht-fast.com/64a7abc9734fdc539a50791d/',
      show_img1: [
        'PCA_all',
        'PCA_cluster4',
        'PCA_cluster6',
        'PCA_cluster8',
        'PCA_cluster12',
        'PCA_spf',
        'PCA_cp',
        'FA_all',
        'FA_cluster4',
        'FA_cluster6',
        'FA_cluster8',
        'FA_cluster12',
        'FA_spf',
        'FA_cp',
      ],
      show_img2: ['_1_2', '_1_3', '_1_4', '_1_5', '_1_6', '_2_3', '_2_4', '_2_5', '_2_6', '_3_4', '_3_5', '_3_6', '_4_5', '_4_6', '_5_6'],
      show_img3: ['_name', '_legend', '_legend_name', ''],
      show_img4: '.png',
      show_img1_index: 0,
      show_img2_index: 0,
      show_img3_index: 3,
    };
  },
  watch: {
    '$store.state.lang': {
      handler() {
        this.contrast = this.contrasts;

        console.log('监听切换');
        if (this.$store.state.lang == 'cn') {
          if (this.category_list[0] == 'Sun care') {
            this.$set(this.category_list, 0, '防晒霜');
            if (this.formInline0.product == 'Sun care') {
              this.formInline0.product = '防晒霜';
            }
          }
        } else if (this.$store.state.lang == 'en') {
          if (this.category_list[0] == '防晒霜') {
            this.$set(this.category_list, 0, 'Sun care');
          }
          if (this.formInline0.product == '防晒霜') {
            this.formInline0.product = 'Sun care';
          }
        }
      },
    },
    'formInline0.product': {
      handler(newv, oldv) {
        console.log(newv);
        if (newv != '防晒霜' && newv != 'Sun care') {
          this.change_show = 1;
          return;
        }
        if (newv != oldv) {
          this.req_products();
        }
        if (newv != '') {
          // this.img_data = "https://newstore.vynior.com/none%202.png"
          // this.change_show = 3;
          // this.change_=true
        }
      },
    },
    // 监听图片
    // figure_up: {
    //   handler(newv, oldv) {
    //     if (newv != oldv) {
    //       if (newv.length != 0) {
    //         this.loading = false;
    //         this.change_ = false;
    //         // this.default_img = this.figure_up.classification[1]
    //         this.pdc_cs = 0;
    //         this.change_show = 0;
    //         this.pdc_cs = 0; //坐标
    //         // this.change_show = 1;
    //         this.radio = 1;
    //       }
    //     }
    //   },
    // },
    // !图片监听

    // !监听坐标
    pdc_cs: {
      handler() {
        console.log(this.pdc_cs, 'pdc_cs的值');
        switch (this.pdc_cs) {
          case 0:
            this.show_img2_index = 0;
            console.log(this.show_img2_index);
            break;
          case 1:
            this.show_img2_index = 1;
            console.log(this.show_img2_index);
            break;
          case 2:
            this.show_img2_index = 2;
            console.log(this.show_img2_index);
            break;
          case 3:
            this.show_img2_index = 3;
            console.log(this.show_img2_index);
            break;
          case 4:
            this.show_img2_index = 4;
            console.log(this.show_img2_index);
            break;
          case 5:
            this.show_img2_index = 5;
            console.log(this.show_img2_index);
            break;
          case 6:
            this.show_img2_index = 6;
            console.log(this.show_img2_index);
            break;
          case 7:
            this.show_img2_index = 7;
            console.log(this.show_img2_index);
            break;
          case 8:
            this.show_img2_index = 8;
            console.log(this.show_img2_index);
            break;
          case 9:
            this.show_img2_index = 9;
            console.log(this.show_img2_index);
            break;
          case 10:
            this.show_img2_index = 10;
            console.log(this.show_img2_index);
            break;
          case 11:
            this.show_img2_index = 11;
            console.log(this.show_img2_index);
            break;
          case 12:
            this.show_img2_index = 12;
            console.log(this.show_img2_index);
            break;
          case 13:
            this.show_img2_index = 14;
            console.log(this.show_img2_index);
            break;
        }
      },
    },
    // ! 第一个按钮
    Switch_Value() {
      // ! 如果false不要legend，同理
      if (this.Switch_Value == false) {
        console.log('我现在是false');
        this.show_img3_index = 3;

        if (this.Switch_Value == false && this.Name_Value === true) {
          this.show_img3_index = 0;
        }
      } else {
        console.log('我现在是true,展示legend');
        this.show_img3_index = 1;
        if (this.Switch_Value === true && this.Name_Value === true) {
          this.show_img3_index = 2;
          console.log('我现在全都是true,legend name');
        }
      }
    },
    // ! 第二个name按钮
    Name_Value() {
      if (this.Name_Value == false) {
        console.log('我现在是false');
        this.show_img3_index = 3;
        if (this.Name_Value == false && this.Switch_Value === true) {
          this.show_img3_index = 1;
        }
      } else {
        this.show_img3_index = 0;
        console.log('我现在是true,展示name');
        if (this.Switch_Value === true && this.Name_Value === true) {
          this.show_img3_index = 2;
          console.log('我现在全都是true, legend name');
        }
      }
    },

    // ! 监听主成分分析,和因素旋转分析
    radio: {
      handler(newValue, oldValue) {
        // tips 新增逻辑
        console.log('newValue,oldValue :>> ', newValue, oldValue);
        if (newValue != oldValue && newValue == 1) {
          this.radios = '1';
          this.radioss = '';
          localStorage.lang == 'cn' ? (this.showClassNames = '默认') : (this.showClassNames = 'None');
        } else {
          this.radioss = '5';
          this.radios = '';
          localStorage.lang == 'cn' ? (this.showClassName = '默认') : (this.showClassName = 'None');
        }
      },
    },
    value: {
      handler(newv, oldv) {
        console.log(newv);
        if (newv != oldv) {
          if (newv == false && this.radio == 1) {
            this.pdc_cs = 0;
            this.img_data = this.figure_up.feature[0][0];
          } else if (newv == true && this.radio == 1) {
            this.pdc_cs = 0;
            this.img_data = this.figure_up.feature[2][0];
          }
        }
      },
    },

    //!监听-主成分分析的列表值
    radios: {
      handler(newValue, oldValue) {
        // tips 新增逻辑
        if (newValue != 1 && newValue != oldValue) {
          this.showClassNames = '默认';
        }
        //
        if (this.radios == 1) {
          this.show_img1_index = 0;
        } else if (this.radios == 3) {
          this.show_img1_index = 5;
        } else if (this.radios == 4) {
          this.show_img1_index = 6;
        }
      },
    },
    //!监听-因素旋转分析的列表值
    radioss: {
      handler(newValue, oldValue) {
        // tips 新增逻辑
        if (newValue != 5 && newValue != oldValue) {
          this.showClassName = '默认';
        }
        if (this.radioss == 5) {
          this.show_img1_index = 7;
        } else if (this.radioss == 7) {
          this.show_img1_index = 12;
        } else if (this.radioss == 8) {
          this.show_img1_index = 13;
        }
      },
    },
    //请求计时器
    timer: {
      handler(newVal) {
        // console.log('计时器生效', newVal);
        if (newVal >= 100) {
          if (newVal % 5 == 0) {
            if (this.result_data == 1) {
              this.req_idAna();
            } else {
              this.product_not_null = false;
              this.loading = false;
              clearInterval(this.time);
            }
          }
        }
        if (newVal == 230) {
          this.reset_show = 1;
        }

        if (newVal > 280 && this.result_data == 1) {
          this.loading = false;
          clearInterval(this.time);
        }
      },
    },

    //监听第一个展示类别
    // ! 算法分析的5个值
    showClassNames(newValue, oldValue) {
      if (newValue != 1 && newValue != oldValue) {
        this.showClassNames == '默认';
      }

      if (this.radios == 1) {
        if (this.showClassNames == '默认' || this.showClassNames == 'None') {
          this.show_img1_index = 0;
        } else if (this.showClassNames == '4') {
          this.show_img1_index = 1;
        } else if (this.showClassNames == '6') {
          this.show_img1_index = 2;
        } else if (this.showClassNames == '8') {
          this.show_img1_index = 3;
        } else if (this.showClassNames == '12') {
          this.show_img1_index = 4;
        }
      } else {
        localStorage.lang == 'cn' ? (this.showClassNames = '默认') : (this.showClassNames = 'None');
      }
    },
    // //监听第二个展示类别
    // !因素旋转分析的列表值的5个值
    showClassName(newValue, oldValue) {
      if (newValue != 5 && newValue != oldValue) {
        this.showClassName == '默认';
      }
      if (this.radioss == 5) {
        if (this.showClassName == '默认' || this.showClassName == 'None') {
          this.show_img1_index = 7;
        } else if (this.showClassName == '4') {
          this.show_img1_index = 8;
        } else if (this.showClassName == '6') {
          this.show_img1_index = 9;
        } else if (this.showClassName == '8') {
          this.show_img1_index = 10;
        } else if (this.showClassName == '12') {
          this.show_img1_index = 11;
        }
      } else {
        localStorage.lang == 'cn' ? (this.showClassName = '默认') : (this.showClassName = 'None');
      }
    },
  },
  methods: {
    ChangeIsShow(e) {
      console.log('e.target.tagName :>> ', e.target.tagName);
      if (e.target.tagName === 'IMG' || e.target.tagName === 'SPAN') {
        this.isShow = !this.isShow;
      }
    },
    // NOTE loading关闭按钮
    loadingClose() {
      clearInterval(this.loadingtimer);
      clearInterval(this.time);
      this.loading = false;
      this.loadingProgress = 0;
      this.loadingtimer = null;
      this.analyzeStatus = true;
    },
    //关闭请求,(请求失败)
    cancel_req() {
      this.cancel_req_show = 0;
    },
    changeRadio(e) {
      console.log(e);
      this.radio = e;
    },

    //限制选择数量
    delete_val(val) {
      console.log(val);
      for (let index = 0; index < val; index++) {
        // this.contrast.pop()
        this.idlist.pop();
      }
      console.log(this.contrast);
    },
    //  !NOTE单张下载图表
    async downloadIMG() {
      if (this.taskID == '') {
        this.$message({
          showClose: true,
          message: this.$t(`message.itemAnalyse.analyse`),
          type: 'warning',
          customClass: 'change_bar',
        });
        return;
      }
      let type;
      let location = this.show_img2_index;

      // this.downloadRole = true;
      // this.downloadLoading = true;
      console.log('typeflase :>> ', type);
      console.log(
        'type',
        this.show_img1_index,
        '11',
        'pic_type',
        this.show_img3_index,
        '22',
        'language',
        this.$store.state.lang,
        '33',
        'location',
        location
      );
      let response = await HttpClient.post(
        'CatAna_task/feature_download',
        {
          // type: "feature",
          //  language:this.$store.state.lang,
          // taskid: this.taskID,
          // type,
          // location,
          type: this.show_img1_index,
          pic_type: this.show_img3_index,
          language: this.$store.state.lang,
          location,
        },
        { responseType: 'arraybuffer' }
      );
      const blob = new Blob([response.data], {
        type: 'application/zip',
      });
      let name = getFilename(response);
      let downloadElement = document.createElement('a');
      let featurehref = window.URL.createObjectURL(blob);
      downloadElement.href = featurehref;
      downloadElement.download = `${name}`;
      // console.log("blob :>> ", blob);
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(featurehref);
      this.downloadLoading = false;
      this.downloadRole = false;
    },
    // NOTE下载图表
    async downloadIMGALL() {
      if (this.taskID == '') {
        this.$message({
          showClose: true,
          message: this.$t(`message.itemAnalyse.analyse`),
          type: 'warning',
          customClass: 'change_bar',
        });
        return;
      }
      this.downloadRole = true;
      this.downloadLoading = true;
      let response = await HttpClient.post(
        'CatAna_task/feature_downloadAll',
        {
          // type: "feature",
          taskid: this.taskID,
          language: this.$store.state.lang,
        },
        { responseType: 'arraybuffer' }
      );
      const blob = new Blob([response.data], {
        type: 'application/zip',
      });
      let name = getFilename(response);
      let downloadElement = document.createElement('a');
      let featurehref = window.URL.createObjectURL(blob);
      downloadElement.href = featurehref;
      downloadElement.download = `${name}`;
      // console.log("blob :>> ", blob);
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(featurehref);
      this.downloadLoading = false;
      this.downloadRole = false;
    },
    // 请求用户的产品品类
    async req_category() {
      let res = await HttpClient.post('subuser/getUesrProduct', {
        _id: this.$store.state.userid,
      });
      console.log(res);

      this.category_list = res.classification;
      if (this.$store.state.lang == 'cn') {
        console.log('this.category_list CN:>> ', this.category_list);
        this.category_list.forEach((item, index) => {
          console.log(item);
          switch (item) {
            case 'Sun care':
              this.$set(this.category_list, index, '防晒霜');
              break;
            case 'Toner':
              this.$set(this.category_list, index, ' 爽肤水');
              break;
            case ' Lotion':
              this.$set(this.category_list, index, '乳液');
              break;
            case 'Hand lotion':
              this.$set(this.category_list, index, '护手霜');
              break;
            default:
              break;
          }
        });
      } else {
        console.log('this.category_list EN :>> ', this.category_list);
        this.category_list.forEach((item, index) => {
          console.log(item);
          switch (item) {
            case '防晒霜':
              // item = 'Sun care';
              this.$set(this.category_list, index, 'Sun care');
              break;
            case '爽肤水':
              item = 'Toner';
              this.$set(this.category_list, index, 'Toner');
              break;
            case '乳液':
              item = 'Lotion';
              this.$set(this.category_list, index, 'Lotion');
              break;
            case '护手霜':
              item = 'Hand lotion';
              this.$set(this.category_list, index, 'Hand lotion');
              break;
            default:
              break;
          }
        });
      }
      // console.log(this.program_name);
    },

    changeName(e) {
      console.log(e);
      this.category = e;
      if (e == 'Sun care') {
        this.category = '防晒霜';
      } else {
        this.category = e;
      }
      console.log('this.category :>> ', this.category);

      this.req_products();
    },

    //获取 对比产品数据
    async req_products() {
      // this.change_show = 3;

      HttpClient.post('program/history_list', {
        user_id: this.$store.state.userid,
        category: this.category,
      }).then((res) => {
        console.log(res);
        let newlist = res.result;
        console.log(newlist);
        let labellist = [];
        newlist.forEach((item) => {
          console.log(item);
          labellist.push(item.label);
          console.log(item.children);
        });
        console.log(labellist);

        this.options = newlist;
        console.log(this.options);
      });
    },
    //开始分析分析接口
    async req_ana() {
      console.log(
        'account_id',
        this.$store.state.userid,
        'user_id',
        this.$store.state.userid,
        'category',
        this.formInline0.product,
        'productlist',
        this.idlist,
        'featureType',
        '1',
        'language',
        this.$store.state.lang
      );
      //计时器
      this.timenum = 1;
      console.log('this.timenum', this.timenum);
      this.timer = 0;
      this.$once('hook:beforeDestroy', () => {
        clearInterval(this.time);
      });
      //loading状态

      if (this.formInline0.product == '' || this.contrast.length == 0) {
        message({
          showClose: true,
          message: this.$t(`message.Message.selectCP`),
          type: 'warning',
          customClass: 'change_bar',
        });
      } else {
        this.time = setInterval(() => {
          this.timer++;
        }, 1000);
        console.log(this.timer);
        this.loading = true;
        this.analyzeStatus = false;
        this.loadingtimer = setInterval(() => {
          this.loadingProgress += 100 / 280;
          if (this.loadingProgress >= 100) {
            if (!this.analyzeStatus) {
              this.$message.warning('分析超时请重新尝试');
            }
            clearInterval(this.loadingtimer);
            this.loadingProgress = 0;
            this.loading = false;
            this.loadingtimer = null;
          }
        }, 1000);
        //判断分类
        // let class_value;
        // if (this.showClassName == "默认") {
        //   class_value = '0';
        // } else if (this.showClassName == "6类") {
        //   class_value = "6";
        // }  else if (this.showClassName == "4类") {
        //   class_value = "4";
        // }  else if (this.showClassName == "8类") {
        //   class_value = "6";
        // } else {
        //   class_value = "12";
        // }
        // console.log('class_value :>> ', class_value);
        HttpClient.post('CatAna_task/feature', {
          account_id: this.$store.state.userid,
          user_id: this.$store.state.userid,
          category: this.formInline0.product,
          productlist: this.idlist,
          featureType: '1',
          language: this.$store.state.lang,
        }).then((res) => {
          console.log(res);
          this.taskID = res.id;
          // this.taskID = "63c10fa1cfb9d30e23c614e8";

          // console.log(this.figure_up);

          //接收导数据,改变坐标的可选;
          if (this.taskID != '') {
            // this.product_not_null = false;
            // this.figure_up = res.result;
            this.analyzeStatus = true;
            this.req_idAna();
          }
          // console.log(this.figure_up);
        });
      }
    },

    // 特征分析根据id找结果
    async req_idAna() {
      HttpClient.post('CatAna_task/findbyid', {
        id: this.taskID,
      }).then((res) => {
        console.log(res, '返回值');
        // let aa=`${this.base}${this.show_img1[this.show_img1_index]}${this.show_img2[this.show_img2_index]}${this.show_img3[this.show_img3_index]}${this.show_img4}`
        // console.log('根据id查找finde 结果是:', res);
        this.result_data = res.status;
        if (res.status != 1) {
          this.loadingProgress = 100;
          this.change_ = false;
          this.figure_up = res.result;
          // ! 拿到第一个数据进行截取
          let url = res.result.feature[0][0];
          const parts = url.split('/');
          const processed = parts.slice(0, 4).join('/') + '/';
          this.base = processed;
          console.log('base 我现在的地址', this.base);
          this.img_data = this.figure_up.feature[0][0];
          this.change_show = 0;
          if (res.result.featureFA) {
            console.log('有东西');
            message({
              showClose: true,
              message: this.$t(`message.Message.inished`),
              type: 'sueecss',
              customClass: 'change_bar',
            });
          } else {
            if (this.Times == 0) {
              message({
                showClose: true,
                message: this.$t(`message.Message.unfinished`),
                type: 'warning',
                customClass: 'change_bar',
              });
            }
            this.Times = this.Times + 1;
            this.req_idAna_run();
            console.log('没有东西');
          }
        }
      });
      if (this.formInline0.product == '' || this.contrast == []) {
        this.$message({
          message: '当前分析未选择品类和产品',
          type: 'warning',
        });
      }
      // else {
      //   this.req_idAna_run();
      // }
      // else {
      //   this.loading = true;
      //   HttpClient.post("CatAna_task/feature", {
      //     account_id: this.$store.state.userid,
      //     user_id: this.$store.state.userid,
      //     category: this.formInline0.product,
      //     productlist: this.idlist,
      //   }).then((res) => {
      //     console.log(res);
      //     console.log(this.figure_up);
      //     this.figure_up = res.result;
      //     this.taskID = res.result._id;
      //     //接收导数据,改变坐标的可选;
      //     if (this.taskID != "") {
      //       this.product_not_null = false;
      //     }
      //     console.log(this.figure_up);
      //   });
      // }
    },
    // ! 没有东西继续请求
    async req_idAna_run() {
      // !减少请求
      setTimeout(() => {
        HttpClient.post('CatAna_task/findbyid', {
          id: this.taskID,
        }).then((res) => {
          this.result_data = res.status;
          if (res.status != 1) {
            this.loadingProgress = 100;
            if (res.result.featureFA != undefined) {
              console.log('有东西了');
              console.log('res.result.featureFA', res.result.featureFA);
              this.figure_up.featureFA = res.result.featureFA;
              message({
                showClose: true,
                message: this.$t(`message.Message.inished`),
                type: 'warning',
                customClass: 'change_bar',
              });
            } else {
              // console.log('继续请求');
              // console.log('res.result.featureFA', res.result.featureFA);
              this.req_idAna_run();
              // message({
              //   showClose: true,
              //   message: this.$t(`message.Message.unfinished`),
              //   type: 'warning',
              //   customClass: 'change_bar',
              // });
            }
          }
        });
        // if (this.formInline0.product == "" || this.contrast ==[]) {
        //   this.$message({
        //     message: "当前分析未选择品类和产品",
        //     type: "warning",
        //   });
        // } else {
        //   this.loading = true;
        //   HttpClient.post("CatAna_task/feature", {
        //     account_id: this.$store.state.userid,
        //     user_id: this.$store.state.userid,
        //     category: this.formInline0.product,
        //     productlist: this.idlist,
        //   }).then((res) => {
        //     console.log(res);
        //     console.log(this.figure_up);
        //     this.figure_up = res.result;
        //     this.taskID = res.result._id;
        //     //接收导数据,改变坐标的可选;
        //     if (this.taskID != "") {
        //       this.product_not_null = false;
        //     }
        //     console.log(this.figure_up);
        //   });
        // }
      }, 2000);
    },
    // 搜索显示  感官数据
    async Cvalue(e) {
      console.log(e);
      // if (e.length > 10) {
      //   this.$message({
      //     showClose: true,
      //     message: "最多只能选择10个对比产品哦！",
      //     type: "warning",
      //     customClass: "change_bar"
      //   });
      this.$nextTick(() => {
        this.$refs.cascaderAddr.checkedValue = this.values;
        this.$refs.cascaderAddr.computePresentContent();
      });
      //   return;
      // }
      this.values = e;
      this.idlist = [];
      let namelist = [];
      let RadarName_List = [];
      // let arr = { name: "", label: "" };
      this.panel_data = [];
      e.forEach((itemo) => {
        // console.log(itemo);
        // console.log(this.options[itemo[0]].children[itemo[1] - 1].productid);
        this.idlist.push(this.options[itemo[0]].children[itemo[1] - 1].productid);

        namelist.push(this.options[itemo[0]].children[itemo[1] - 1].label);
        RadarName_List.push(this.options[itemo[0]].label);
      });
      console.log(this.idlist); //获取到的产品id 数组
      console.log(namelist); //获取到的产品名 数组
      console.log(RadarName_List); //获取到的产品名 数组  对应的 主产品名

      // this.req_pdResarch();
      this.contrasts = this.contrast;
    },
    //获取分析结果（不是开始分析）
    async req_pdResarch() {
      HttpClient.post('CatAna_task/find', {
        cond: {
          id: this.$store.state.userid, //客户ID
          category: this.formInline0.product, //产品品类
          type: '2', //分析类别（1产品分类2产品特征3产品轮廓4产品重要度）
          productlist: this.idlist, //产品id数组（重要度分析无，能选产品的有，就是type为4的不要传这个）
        },
      }).then((res) => {
        console.log(res);
      });
    },

    //清楚选项
    clear_num() {
      this.contrast = [];
      // this.$refs['cascaderAddr'].panel.clearCheckedNodes();
    },
    //重置按钮
    reset_() {
      // this.radio = '1';
      // this.radios = '1';
      // this.value = false;
      if (!this.product_not_null) {
        this.pdc_cs = 0;
      }
      // this.change_show = 1;
    },
    goPathName() {
      this.next = true;
      this.$router.push({ path: this.pathName });
    },
    clearFocus() {
      this.Leave = false;
      this.$EventBus.$emit('focusValue', [0, 0, 0, 1, 0]);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.timenum == 0) {
      next();
    }
    console.log(to, from);
    console.log('to :>> ', to);
    console.log('from :>> ', from);
    console.log('from :>> ', from);
    this.Leave = true;
    this.pathName = to.name;
    if (this.next == true) {
      next();
    }
  },
  mounted() {
    this.show_img1_index = 0;
    (this.show_img2_index = 0),
      (this.show_img3_index = 3),
      // ! 初始化和数据
      //! 初始化   pdc_c.pdc_cs   radio:1   radios:1
      (this.pdc_c.pdc_cs = 0);
    this.radio = 1;
    this.radios = 1;

    this.timenum = 0;
    this.req_category();
    //
    document.addEventListener('click', (e) => {
      if (this.downloadShow == true) {
        if (e.target.id != 'downloadImg') {
          this.downloadShow = false;
        }
      }

      if (this.isShow == true) {
        if (e.target.id != 'defaults') {
          this.isShow = false;
        }
      }
    });
  },
};
</script>

<style lang="less" scoped>
@import url('../../style/Acategory/Characteristic.less');
</style>
